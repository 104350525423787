module UtilService {
    let app = angular.module("app");

    app.factory("UtilService", ["$resource", "GlobalApplicationData", ($resource: ng.resource.IResourceService, GlobalApplicationData: any) => {
        let serv = {
            // Recupera le news dell'utente
            getAllCounters: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-all-counters-by-user', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            getUsersAndTheirCoursesCounter: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-users-and-their-courses-counter', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            getCoursesByUserIdForSystem: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-courses-by-user-id-for-system', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            findUsersByTenant: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/find-users-by-tenant', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera tutti gli utenti di uno specifico tenant (senza eseguire anche il count)    
            getUsersByTenant: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-users-by-tenant', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

                // Recupera tutti gli attestati (senza eseguire anche il count)    
            getCertificates: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/list-approved-extra-courses', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera tutti gli utenti di uno specifico tenant (senza eseguire anche il count)    
            getUsersByUserIds: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-users-by-userIds', {
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera tutti gli utenti in base ad un filtro   
            findUserByAnyField: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/find-users-by-any-field', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            exportUserCourses: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/export-user-courses', {
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera il numero degli utenti totali di uno specifico tenant
            countUsersByTenant: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/count-users-by-tenant', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

                // Recupera il numero degli attestati totali
            countCertificates: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/count-approved-extra-courses', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera tutti i docenti e tutor   
            getSuppliers: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-supplier-people-list', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera il numero dei docenti e tutor
            countSuppliers: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/count-supplier-people-list', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera tutti fornitori   
            getAnagSuppliers: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-suppliers', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera il numero dei fornitori
            countAnagSuppliers: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/count-suppliers', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera la lista dei gruppi
            searchGroups: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/search-groups/:fromRecord/:numRecords', {
                fromRecord: "@fromRecord",
                numRecords: "@numRecords"
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),


            // Recupera la lista dei gruppi
            getUserIdsByGroupIds: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/userIds-by-groupIds', {
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Aggiunge gli utenti alla lista di supplier
            addUsersToSupplierList: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/add-users-to-supplier-people', {
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Rimuove gli utenti alla lista di supplier
            deleteUsersFromSupplierPeople: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/delete-users-from-supplier-people', {
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera il supplier dato un id 
            getSupplierById: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-anag-supplier-by-id', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera il count dei gruppi
            countGroups: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/count-groups', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Aggiunge una lista di utenti ad un gruppo
            addUsersToGroup: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/add-users-to-group', {
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Crea un supplier (docente/tutor)
            addSupplierPeople: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/add-supplier-people', {
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Crea un fornitore
            addAnagSupplierPeople: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/add-anag-supplier', {
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Invia una mail per vedere la preview del template
            sendPreviewTemplateMail: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/send-preview-template-mail', {
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Aggiorna un supplier (docente o tutor)
            updateSupplierPeople: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/update-supplier-people', {
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Aggiorna un supplier dell'anagrafica
            updateAnagSupplierPeople: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/update-anag-supplier', {
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Aggiorna un supplier dell'anagrafica
            deleteAnagSupplierPeople: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/delete-anag-supplier', {
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Rimuove un gruppo
            deleteGroup: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/delete-group/:groupId', {
                groupId: "@groupId"
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Crea un gruppo
            createGroup: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/create-group', {
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Rimuove gli utenti selezionati dal gruppo
            removeUsersFromGroup: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/remove-users-from-group', {
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Aggiorna il gruppo
            updateGroup: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/update-group', {
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera le informazioni di un gruppo
            getGroupById: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-group-by-id/:groupId/:withUsersData', {
                groupId: "@groupId",
                withUsersData: "@withUsersData"
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera le informazioni di un gruppo
            getUploadsByReference: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-all-upload-by-reference', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            deleteApprovedExtraCourseDate: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/delete-approved-extra-course', {
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                })
                
        };
        return serv;
    }]);
}